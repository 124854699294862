import T from '../../../common/components/T';

export const footerInfo = {
    phone: { number: '+77470945583', title: '+7 747 094 55 83' },
    whatsapp_tech: { number: '+7 708 112 14 59', link: 'https://wa.me/77081121459' },
    whatsapp_cons: { number: '+7 747 990 92 49', link: 'https://wa.me/77479909249' },
    address: 'г.Алматы ул.Курмангазы 145',
    timeWork: <T textName='storeTimework' defaultStr='с 10:00 до 21:00 без выходных' page='/'/>,
    subtitle: <T textName='storeFooterSubtitle' defaultStr='Бесплатный звонок со стационарных и мобильных телефонов'
        page='/'/>,
    social: [
        // {
        //     type: 'facebook',
        //     link: '',
        //     background: 'rgba(72,103,170,1)',
        //     icon: 'facebookFooter',
        //     width: '12px',
        //     height: '19px'
        // },
        {
            type: 'instagram',
            link: 'https://www.instagram.com/alhadaya_kz/',
            background: 'linear-gradient(45deg, #FFC107 9.15%, #F44336 50.57%, #9C27B0 90.03%)',
            icon: 'instagramFooter',
            width: '17px',
            height: '17px'
        },
        {
            type: 'youtube',
            link: 'https://www.youtube.com/@Alhadaya/featured',
            background: 'rgba(255,0,0,1)',
            icon: 'youtubeFooter',
            width: '18px',
            height: '14px'
        }
        // {
        //     type: 'telegram',
        //     link: '',
        //     background: 'rgba(37,163,225,1)',
        //     icon: 'telegramFooter',
        //     width: '15px',
        //     height: '14px'
        // }
    ]
};